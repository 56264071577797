<template>
  <div class="markdown-body hljs" v-highlight id="markdown-article" v-html='content'>
  </div>
</template>

<script>

export default {
  data() {
  	return {
		loadingA: true,
		imgs: []
	}
  },
  props:['content','aid','loading'],
  methods: {
	  addLazy(){
		  setTimeout(function(){
			let imgs = document.getElementById('markdown-article').querySelectorAll('img')
			for (let img of imgs) {
				img.setAttribute('preview','1')
				img.setAttribute('preview-text',img.getAttribute('alt'))
			}
		  },100)
	  }
  },
  watch: {
	  async content(){
		  this.addLazy()
		  await this.$nextTick()
		  this.$previewRefresh()
	  }
  }
};
</script>

<style scoped>
@import "/css/MWeb.css";

.markdown-body{
	font-family: "Source Sans Pro","Hiragino Sans GB","Microsoft Yahei",SimSun,Helvetica,Arial,Sans-serif;
	width: 55%;
	text-align: start;
	margin: 3em auto;
	margin-bottom: 0;
	background-color: rgba(255,255,255,.3);
	padding: 0 2em;
}
.darkApp .markdown-body{
	background-color: var(--theme-background-color);
}
.darkApp .hljs{
	color: #d8d8d8;
}
@media screen and (max-width: 1400px) {
	.markdown-body{
		width: 65%;
	}
}
@media screen and (max-width: 770px) {
	.markdown-body{
		width: 90%;
		padding: .5em;
	}
}
</style>