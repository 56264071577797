<template>
	<div class="article">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>
		<ArticleC :aid='aid' :content='content' :loading='loading'></ArticleC>
		<Share :title='pageMsg.title' :tags='tags' :description='pageMsg.article.description'></Share>
		<MessageC :messages='comments' :aid='aid' :commentable='article.commentable' @getMsg='initPage'></MessageC>
	</div>
</template>

<script>
import TopCover from '@/components/TopCover.vue'
import Nav from '@/components/Nav.vue'
import MessageC from '@/components/Message.vue'
import ArticleC from '@/components/ArticleC.vue'
import Share from '@/components/Share.vue'

export default {
  name: 'Article',
  components: {
    TopCover,
	Nav,
	MessageC,
	ArticleC,
	Share
  },
  data() {
  	return {
		loading: true,
		article: '',
		metaDate: [
			{
			    name: 'description',
			    content: 'KASUIEの次元，兴趣至上，内容随缘',
			},
			{
			    name: 'keywords',
			    content: 'KASUIEの次元,KASUIE的个人博客,KASUIE,个人博客',
			}
		], 
		desc: '',
		keywords: '',
		content: '',
		comments: null,
		aid: null,
		tags: [],
		pageMsg:{
			title: '',
			imgUrl: '',
			article: ''
		},
	}
  },
  methods: {
	  initPage(){
		  let that = this
		  let aid = this.$route.params.aid
		  this.axios.get('getArticle?aid='+aid).then(function(results){
		  	that.article = results.data.articles[0][0]
		  	that.comments = results.data.comments[0]
			// that.desc = that.article.desc
			// that.keywords = that.article.tags
			if(that.article.tags!=null){
				that.tags = that.article.tags.split(',')
			}
			document.title = that.article.title
			let title
			setTimeout(function() {
			   	title = document.title
			}, 1000);
			window.onfocus = function () {
				document.title = '恢复正常了...';
			    setTimeout(function() {
					document.title = title
				}, 1000);
			};
			window.onblur = function () {
				setTimeout(function() {
					document.title = '快回来~页面崩溃了'
				}, 5000);
			};
		  },function(err){
		  	console.log(err)
		  })
	  },
  },
  watch: {
	  article(){
		  this.pageMsg.title = this.article.title
		  this.pageMsg.imgUrl = this.article.topImg
		  this.article.countsComment = this.comments.length
		  this.pageMsg.article = this.article
		  this.aid = this.article.aid
		  this.content = this.article.content
	  },
  },
  created() {
  	this.initPage()
  },
  metaInfo() {
      return {
		  meta: this.metaDate
	  }
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style scoped>

</style>
