<template>
	<div class="share" id="share">	
		<div class="actile-end">
			<hr>END<hr>
		</div>
		<div class="article-tags">
			<el-tag v-for="(tag,index) in tags" :key='index' v-text="tag" :type='types[Math.floor(Math.random() * 5)]'></el-tag>
		</div>
<!-- 	    <div class="share-btn">
	        <a href="javascript:void(0)" @click="shareQ()"><img src="/img/kasuie/qzone.png" alt="qzone" id="qzone" title="分享到QQ空间"></a>
	        <a href="javascript:void(0)" @click="shareW()"><img src="/img/kasuie/weibo.png" alt="weibo" id="weibo" title="分享到微博"></a>
	    </div> -->
		<div class="middle">
		  <a class="btn" href="javascript:void(0)" @click="shareQ()" title="分享到QQ空间">
		    <i class="fa fa-qq"></i>
		  </a>
		  <a class="btn" href="javascript:void(0)" title="暂不可用">
		    <i class="fa fa-twitter"></i>
		  </a>
		  <a class="btn" href="javascript:void(0)" @click="shareW()" title="分享到微博">
		    <i class="fa fa-weibo"></i>
		  </a>
		  <a class="btn" href="javascript:void(0)" title="暂不可用">
		    <i class="fa fa-google-plus"></i>
		  </a>
		</div>
		<div class="article-coffee">
			
		</div>
	</div>
</template>

<script>
export default {
	name: 'Share',
	data() {
		return {
			summary: 'KASUIEの次元',
			pics: 'https://kasuie.top/user/avatar/824a8390-ce02-4b41-984f-cfb00eca0bcb.jpg',
			t: '',
			desc: '',
			types: ['','success','info','warning','danger']
		}
	},
	props:['title', 'description','tags'],
	methods: {
		shareQ(){
			let urlPath = "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url="+ encodeURI(window.location.href) +"&desc=" + this.description +"&title=" + this.title +"&summary=" + this.summary +"&pics=" + this.pics;
			window.open(urlPath)
		},
		shareW(){
			let urlPath = "http://service.weibo.com/share/share.php?url="+ encodeURI(window.location.href) +
			    "&desc=" + this.description +
			    "&title=" + this.title +
			    "&summary=" + this.summary +
			    "&pics=" + this.pics;
			window.open (urlPath)
		},
		shareT(){
			let metaArr = {
				'twitter:url': encodeURI(window.location.href),
				'twitter:site': encodeURI(window.location.href),
				'twitter:title': this.title,
				'twitter:description': this.desc,
				'twitter:card': 'summary_large_image',
				'twitter:image': this.pics
			}
			let metaParams = metaArr.toString()
			metaParams = encodeURIComponent(encodeURIComponent(metaParams))
			// window.open(`https://twitter.com/share?text=${this.title}&url=http://java.chendahai.cn/share/new?meta=${metaParams}`)
			console.log(metaParams)
		}
	},
	watch: {
		title(){
			this.t = this.title
		},
		description(){
			this.desc = this.description
		},
	}
}
</script>

<style>
#share{
	position: relative;
	width: 55%;
	margin: 0 auto;
	margin-top: 0;
	text-align: right;
	padding: 2em 2em 1em 2em;
	background-color: rgba(255,255,255,.3);
}
#share .actile-end{
	position: relative;
	display: table;
	white-space: nowrap;
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align: center;
	font-weight: 700;
	font-size: larger;
}
.article-tags{
	position: absolute;
	left: 2em;
	display: inline-block;
}
.article-tags span{
	margin-right: .5em;
}
.share-btn{
	display: inline-block;
}
#share .actile-end hr{
	position: absolute;
	background-color: deepskyblue;
	width: 45%;
	top: 50%;
}
#share .actile-end hr:nth-last-child(1){
	right: 0;
}
#share div img{
	vertical-align: middle;
	border-radius: 50%;
	transition: all ease-in-out .3s;
}
#share div img:hover{
	transform: scale(1.2);
	background-color: whitesmoke;
}
.darkApp #share{
	background-color: var(--theme-background-color);
}
.darkApp .el-tag{
	background-color: #000000 !important;
}
.middle {
  margin:0;
  padding:0;
/*  position: absolute;
  top:50%;
  transform: translateY(-50%); */
  /* width: 100%; */
  /* text-align: center; */
  display: inline-block;
}

.btn {
  display:inline-block;
  width:2em;
  height: 2em;
  line-height: 2em;
  background: #f1f1f1;
  margin: 0 10px;
  border-radius: 30%;
  box-shadow:-5px 5px 15px -5px #f1f1f1; 
  overflow: hidden;
  position: relative;
  transition: 0.3s linear;
  text-align: center;
}

.btn i {
  font-size: 1em;
  transition: 0.3s linear;
}

.btn:nth-child(1) i {
  color:#3b5998;
}

.btn:nth-child(2) i {
  color:#1da1f2;
}

.btn:nth-child(3) i {
  color:#c32aa3;
}

.btn:nth-child(4) i {
  color:#db4437;
}

.btn:hover {
  transform: scale(1.1);
}

.btn:hover i {
  transform: scale(1.2);
  color: #fff;
}

.btn:before {
  content:"";
  position:absolute;
  width:120%;
  height:120%;
  transform: rotate(45deg);
  left: -110%;
  top:90%;
}

.btn:nth-child(1)::before {
  background: #3b5998;
}

.btn:nth-child(2)::before {
  background: #1da1f2;
}

.btn:nth-child(3)::before {
  background: #c32aa3;
}

.btn:nth-child(4)::before {
  background: #db4437;
}

.btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}

@keyframes aaa {
  0% {
    left: -110%;
    top:90%;
  }
  50% {
    left:10%;
    top: -30%;
  }
  100% {
    left: -10%;
    top: -10%;
  }
}
@media screen and (max-width: 1400px) {
	#share{
		width: 65%;
	}
}
@media screen and (max-width: 770px) {
	#share{
		width: 90%;
		padding: 2em .5em 1em .5em;
	}
	.article-tags{
		left: 1em;
	}
	.article-tags span{
		margin-right: .1em;
	}
	#share .actile-end hr{
		width: 40%;
	}
	.btn{
		margin: 0 5px;
	}
}
</style>
